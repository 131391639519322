html,body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

.App section{display:flex;justify-content:space-around}.App section>*{width:60%}

.conditions-page .save-button{position:fixed;bottom:2em;right:2em}

.selectable-tree .segment{padding:0;margin:0}.selectable-tree .maker-list-checkbox{display:flex;align-items:center}.selectable-tree .maker-list-checkbox label{cursor:pointer;padding:0 0.5em 0 0.5em}.selectable-tree [viewtype="conditions"] .input,.selectable-tree [viewtype="policy"] .input{margin-top:2px;width:100px;height:30px}.selectable-tree [viewtype="conditions"] .content,.selectable-tree [viewtype="policy"] .content{margin:0 0 10px 10px}.selectable-tree [viewtype="policy"] .input{margin-left:25px}.selectable-tree [viewtype="policy"] .engine-details{margin-left:25px}

header nav{height:3.5em;box-shadow:0 0.5rem 1rem rgba(0,0,0,0.15);background-color:#343a40;display:flex;justify-content:space-between;align-items:center;margin-bottom:1em}header nav .right-content{display:flex;align-items:center}header nav .right-content .username{color:white;padding:0 1em 0 1em}header nav .left-content,header nav .right-content{padding:0.4em}

.tenant-list .action-column{display:flex;justify-content:space-around}

.policies-page .save-button{position:fixed;bottom:2em;right:2em}.policies-page .quill{width:100%;height:100%}.policies-page .quill .ql-editor{height:150px}

.tenant-user-list .action-column{display:flex;justify-content:center}

